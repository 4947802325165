var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domande_caricate
    ? _c(
        "div",
        {
          staticClass: "row justify-center",
          on: {
            input: function ($event) {
              return _vm.resultDomande()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "col-12 col-md-10", attrs: { align: "left" } },
            [
              _vm._m(0),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Cognome, Nome / Ragione Sociale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getValueFromNameCliente("cognome")) +
                                " " +
                                _vm._s(_vm.getValueFromNameCliente("nome")) +
                                " " +
                                _vm._s(
                                  _vm.getValueFromNameCliente("ragione_sociale")
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Indirizzo"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente(
                                  "indirizzo_residenza"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente("comune_residenza")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente(
                                  "provincia_residenza"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("CAP"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente("cap_residenza")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Codice Fiscale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente("codice_fiscale")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Partita IVA"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getValueFromNameCliente("partita_iva"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente("comune_nascita")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getValueFromNameCliente("provincia_nascita")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm.isMultirischioCasa
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio della casa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Tipologia di utilizzo dell'abitazione"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "tipo_dimora"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [_vm._v("Tipo di Abitazione")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_abitazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "A quale piano si trova l'Immobile?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "piano_abitazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_casa.indirizzo_casa",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_casa
                                                .indirizzo_casa,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_multirischio_casa
                                            .comune_casa
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_casa.comune_casa",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_multirischio_casa
                                                    .comune_casa,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "update:domanda": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_multirischio_casa.cap_casa
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_casa.cap_casa",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_multirischio_casa
                                                    .cap_casa,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "provincia_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Quanti sono i metri quadri dell`Immobile?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "metri_quadri"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Anno di costruzione del fabbricato?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Quale e` l`anno di ultima ristrutturazione dell'Immobile?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_ultima_ristrutturazione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(1),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                        attrs: { caption: "" },
                                      },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCompagniaSelezionata
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive,
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                            attrs: { caption: "" },
                                          },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.analizzaRisposta(garanzia)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_schifata"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_casa"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_casa"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_casa"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_casa"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_casa.data_sinistro_casa",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_casa
                                                      .data_sinistro_casa,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_casa"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_casa.descrizione_evento_casa",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_casa
                                                      .descrizione_evento_casa,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_casa"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_casa.importo_liquidato_casa",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_casa
                                                      .importo_liquidato_casa,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isInfortuni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Infortuni")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_infortuni
                                        .contraente_uguale_assicurato
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_infortuni.contraente_uguale_assicurato",
                                          attrs: {
                                            domanda:
                                              _vm.domande_infortuni
                                                .contraente_uguale_assicurato,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_infortuni.contraente_uguale_assicurato
                              .value === "no"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_infortuni.tipo_persona",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_infortuni
                                                    .tipo_persona,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                    "persona_fisica"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.cognome",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .cognome,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.nome",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .nome,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.codice_fiscale",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .codice_fiscale,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.sesso",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .sesso,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                    "professionista"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.cognome",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .cognome,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.nome",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .nome,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.partita_iva",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .partita_iva,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                    "persona_fisica"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.stato_nascita_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .stato_nascita_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.data_nascita",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .data_nascita,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                      "persona_fisica" &&
                                    _vm.domande_infortuni
                                      .stato_nascita_infortuni.value ===
                                      "ITALIA"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.comune_nascita_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .comune_nascita_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onDomandaChange(
                                                          $event
                                                        )
                                                      },
                                                    "qq-domanda-inputSelect-filter":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.cap_nascita_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .cap_nascita_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.provincia_nascita_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .provincia_nascita_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                      "persona_fisica" ||
                                    _vm.domande_infortuni.tipo_persona.value ===
                                      "professionista"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.indirizzo_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .indirizzo_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.comune_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .comune_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onDomandaChange(
                                                          $event
                                                        )
                                                      },
                                                    "qq-domanda-inputSelect-filter":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.cap_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .cap_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.provincia_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .provincia_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                    "societa"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.ragione_sociale",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .ragione_sociale,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.partita_iva",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .partita_iva,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_infortuni.tipo_persona.value ===
                                    "societa"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.indirizzo_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .indirizzo_sede_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.comune_sede_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .comune_sede_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onDomandaChange(
                                                          $event
                                                        )
                                                      },
                                                    "qq-domanda-inputSelect-filter":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.cap_sede_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .cap_sede_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_infortuni.provincia_sede_infortuni",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_infortuni
                                                        .provincia_sede_infortuni,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qual'è l'ttività svolta dall'Assicurato?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "L`Assicurato ha più di 75 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "eta_assicurato_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "accertamenti_diagnostici"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "accertamenti_diagnostici"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "subito_ricoveri"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "subito_ricoveri"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "dipendenze"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenze"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "trauni_fratture"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "trauni_fratture"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "pensione_rendita_invalidita_infortunio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "pensione_rendita_invalidita_infortunio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "pensione_rendita_invalidita_malattia"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "pensione_rendita_invalidita_malattia"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "tipologia_copertura"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_copertura"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_infortuni.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_infortuni
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_infortuni"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_infortuni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_infortuni"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_infortuni"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_infortuni.data_sinistro_infortuni",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_infortuni
                                                      .data_sinistro_infortuni,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_infortuni"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_infortuni.descrizione_evento_infortuni",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_infortuni
                                                      .descrizione_evento_infortuni,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_infortuni"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_infortuni.importo_liquidato_infortuni",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_infortuni
                                                      .importo_liquidato_infortuni,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioCommercio
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio del Commercio")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "settore_merceologico_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "settore_merceologico_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "merce_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "merce_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivita_aggiuntiva_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_aggiuntiva_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "attivita_aggiuntiva_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "settore_merceologico_aggiuntivo_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "settore_merceologico_aggiuntivo_commercio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "attivita_aggiuntiva_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "merce_commercio_aggiuntiva"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "merce_commercio_aggiuntiva"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_soci_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_soci_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "dipendenti_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenti_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "dipendenti_commercio"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "numero_dipendenti_commercio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "numero_dipendenti_commercio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_costruzione_commercio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_costruzione_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_commercio.numero_piani_edificio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_commercio
                                            .numero_piani_edificio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_commercio.metri_quadri",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_commercio
                                            .metri_quadri,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_commercio.anno_costruzione",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_commercio
                                            .anno_costruzione,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_commercio.ubicazione_diverso_sede",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_commercio
                                            .ubicazione_diverso_sede,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_multirischio_commercio
                              .ubicazione_diverso_sede.value === "si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_commercio.indirizzo_commercio",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_commercio
                                                .indirizzo_commercio,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_commercio.comune_commercio",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_commercio
                                                .comune_commercio,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_commercio.cap_commercio",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_commercio
                                                .cap_commercio,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_commercio.provincia_commercio",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_commercio
                                                .provincia_commercio,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_commercio.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_commercio
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_commercio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_commercio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_commercio"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_commercio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_commercio.data_sinistro_artigiano",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_commercio
                                                      .data_sinistro_artigiano,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_commercio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_commercio.descrizione_evento_artigiano",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_commercio
                                                      .descrizione_evento_artigiano,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_commercio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_commercio.importo_liquidato_artigiano",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_commercio
                                                      .importo_liquidato_artigiano,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioArtigiano
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio dell`Artigianato")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "settore_merceologico"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "settore_merceologico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "merce_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "merce_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivita_aggiuntiva_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_aggiuntiva_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "attivita_aggiuntiva_artigiano"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "settore_merceologico_aggiuntivo_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "settore_merceologico_aggiuntivo_artigiano"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "attivita_aggiuntiva_artigiano"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "merce_aggiuntiva_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "merce_aggiuntiva_artigiano"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_soci_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_soci_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "dipendenti_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenti_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "dipendenti_artigiano"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "numero_dipendenti_artigiano"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "numero_dipendenti_artigiano"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_costruzione_artigiano"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_costruzione_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_artigiano.numero_piani_edificio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_artigiano
                                            .numero_piani_edificio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_artigiano.metri_quadri",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_artigiano
                                            .metri_quadri,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_artigiano.anno_costruzione",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_artigiano
                                            .anno_costruzione,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_artigiano.ubicazione_diverso_sede",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_artigiano
                                            .ubicazione_diverso_sede,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_multirischio_artigiano
                              .ubicazione_diverso_sede.value === "si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_artigiano
                                          .ubicazione_diverso_sede.value ===
                                        "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_artigiano.indirizzo_artigiano",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_artigiano
                                                    .indirizzo_artigiano,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_artigiano
                                          .ubicazione_diverso_sede.value ===
                                        "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_artigiano.comune_artigiano",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_artigiano
                                                    .comune_artigiano,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_artigiano
                                          .ubicazione_diverso_sede.value ===
                                        "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_artigiano.cap_artigiano",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_artigiano
                                                    .cap_artigiano,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_artigiano
                                          .ubicazione_diverso_sede.value ===
                                        "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_artigiano.provincia_artigiano",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_artigiano
                                                    .provincia_artigiano,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_artigiano.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_artigiano
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_artigiano"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_artigiano"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_artigiano"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_artigiano"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_artigiano.data_sinistro_artigiano",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_artigiano
                                                      .data_sinistro_artigiano,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c("Domanda", {
                                            ref: "domande_multirischio_artigiano.descrizione_evento_artigiano",
                                            attrs: {
                                              domanda:
                                                _vm
                                                  .domande_multirischio_artigiano
                                                  .descrizione_evento_artigiano,
                                            },
                                            on: {
                                              "update:isValid":
                                                _vm.onDomandaValidated,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c("Domanda", {
                                            ref: "domande_multirischio_artigiano.importo_liquidato_artigiano",
                                            attrs: {
                                              domanda:
                                                _vm
                                                  .domande_multirischio_artigiano
                                                  .importo_liquidato_artigiano,
                                            },
                                            on: {
                                              "update:isValid":
                                                _vm.onDomandaValidated,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isGlobaleFabbricato
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio del Condominio")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_costruzione_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.anno_ristrutturazione",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .anno_ristrutturazione,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.totale_fabbricati",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .totale_fabbricati,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.piani_interrati",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .piani_interrati,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.piani_fuori_terra",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .piani_fuori_terra,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "negozi_cinema_fabbricato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "negozi_cinema_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.metri_quadri_cinema",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .metri_quadri_cinema,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.metri_quadri_negozi",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .metri_quadri_negozi,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.attivita_negozi",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .attivita_negozi,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.indirizzo_condominio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .indirizzo_condominio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.comune_condominio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .comune_condominio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                        "qq-domanda-change": function ($event) {
                                          return _vm.onDomandaChange($event)
                                        },
                                        "qq-domanda-inputSelect-filter":
                                          function ($event) {
                                            return _vm.onInputSelectFilter(
                                              $event
                                            )
                                          },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.cap_condominio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .cap_condominio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_globale_fabbricato.provincia_condominio",
                                      attrs: {
                                        domanda:
                                          _vm.domande_globale_fabbricato
                                            .provincia_condominio,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_globale_fabbricato.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_globale_fabbricato
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_fabbricato"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_fabbricato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_fabbricato"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_fabbricato"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_globale_fabbricato.data_sinistro_fabbricato",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_globale_fabbricato
                                                      .data_sinistro_fabbricato,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_fabbricato"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_globale_fabbricato.descrizione_evento_fabbricato",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_globale_fabbricato
                                                      .descrizione_evento_fabbricato,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_fabbricato"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_globale_fabbricato.importo_liquidato_fabbricato",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_globale_fabbricato
                                                      .importo_liquidato_fabbricato,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPolizzaVirus
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza 3-Virus")]),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_globale_fabbricato.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_globale_fabbricato
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_virus"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_virus"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTutelaReddito
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Protezione del reddito")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "eta_assicurato_reddito"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "eta_assicurato_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_lavoro_reddito"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_lavoro_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                ) === "DIPENDENTE_PRIVATO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tempo_indeterminato_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tempo_indeterminato_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                ) === "DIPENDENTE_PUBBLICO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tempo_indeterminato_pubblico_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tempo_indeterminato_pubblico_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                ) === "PROFESSIONISTA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                ) === "IMPRENDITORE"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                ) === "AZIENDA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tempo_partitaiva_reddito"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_tutela_reddito.descrizione_lavoro_svolto",
                                      attrs: {
                                        domanda:
                                          _vm.domande_tutela_reddito
                                            .descrizione_lavoro_svolto,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "perdita_impiego_reddito"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "perdita_impiego_reddito"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_globale_fabbricato.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_globale_fabbricato
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isVeterinaria
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Spese Veterinarie")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "totale_animali_veterinaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "totale_animali_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "animali_rabbiosio_veterinaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "animali_rabbiosio_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c("q-item-section", [
                                  _vm._v("Tipo di Animale"),
                                ]),
                                _c("q-item-section", [_vm._v("Nome")]),
                                _c("q-item-section", [_vm._v("Razza")]),
                                _c("q-item-section", [_vm._v("Eta` anni")]),
                                _c("q-item-section", [_vm._v("Num.Microchip")]),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_veterinaria.tipo_animale1",
                                      attrs: {
                                        domanda:
                                          _vm.domande_veterinaria.tipo_animale1,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_veterinaria.nome_animale1",
                                      attrs: {
                                        domanda:
                                          _vm.domande_veterinaria.nome_animale1,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_veterinaria.razza_animale1",
                                      attrs: {
                                        domanda:
                                          _vm.domande_veterinaria
                                            .razza_animale1,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_veterinaria.eta_animale1",
                                      attrs: {
                                        domanda:
                                          _vm.domande_veterinaria.eta_animale1,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_veterinaria.microchip_animale1",
                                      attrs: {
                                        domanda:
                                          _vm.domande_veterinaria
                                            .microchip_animale1,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.totale_animali > 1
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.tipo_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .tipo_animale2,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.nome_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .nome_animale2,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.razza_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .razza_animale2,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.eta_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .eta_animale2,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.microchip_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .microchip_animale2,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 2
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.tipo_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .tipo_animale3,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.nome_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .nome_animale3,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.razza_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .razza_animale3,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.eta_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .eta_animale3,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.microchip_animale2",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .microchip_animale3,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 3
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.tipo_animale4",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .tipo_animale4,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.nome_animale4",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .nome_animale4,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.razza_animale4",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .razza_animale4,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.eta_animale4",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .eta_animale4,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.microchip_animale4",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .microchip_animale4,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_animali > 4
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.tipo_animale5",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .tipo_animale5,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.nome_animale5",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .nome_animale5,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.razza_animale5",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .razza_animale5,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.eta_animale5",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .eta_animale5,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.microchip_animale5",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .microchip_animale5,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_veterinaria.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_veterinaria"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_veterinaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_veterinaria"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_veterinaria"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.data_sinistro_veterinario",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .data_sinistro_veterinario,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_veterinaria"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.descrizione_evento_veterinario",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .descrizione_evento_veterinario,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_veterinaria"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.importo_liquidato_veterinario",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .importo_liquidato_veterinario,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioAlbergo
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Albergo")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_albergo.ubicazione_uguale_sede",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_albergo
                                            .ubicazione_uguale_sede,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.domande_multirischio_albergo
                                  .ubicazione_uguale_sede.value === "no"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_multirischio_albergo
                                              .indirizzo
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.indirizzo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .indirizzo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_multirischio_albergo
                                              .comune_albergo
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.comune_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .comune_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                  "qq-domanda-change":
                                                    function ($event) {
                                                      return _vm.onDomandaChange(
                                                        $event
                                                      )
                                                    },
                                                  "qq-domanda-inputSelect-filter":
                                                    function ($event) {
                                                      return _vm.onInputSelectFilter(
                                                        $event
                                                      )
                                                    },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_multirischio_albergo
                                              .cap_albergo
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.cap_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .cap_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_multirischio_albergo
                                              .provincia_albergo
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.provincia_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .provincia_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_albergo.denominazione_struttura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_albergo
                                            .denominazione_struttura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_albergo.posti_letto",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_albergo
                                            .posti_letto,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_albergo.valutazione",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_albergo
                                            .valutazione,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "struttura_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "struttura_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_costruzione_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_costruzione_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "anno_costruzione_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_costruzione_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "piani_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "piani_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_albergo.superficie_metri_quadri",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_albergo
                                            .superficie_metri_quadri,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_soci_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_soci_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "dipendenti_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenti_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_dipendenti_albergo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_dipendenti_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_albergo.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_albergo"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_albergo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_albergo"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_albergo"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.data_sinistro_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .data_sinistro_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_albergo"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.descrizione_evento_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .descrizione_evento_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_albergo"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_albergo.importo_liquidato_albergo",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_albergo
                                                      .importo_liquidato_albergo,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isDeO
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza D & O")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "forma_giuridica_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "forma_giuridica_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "forma_giuridica_deo"
                                ) === "ASSOCIAZIONE"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_associazione_deo"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_associazione_deo"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "forma_giuridica_deo"
                                ) === "SOCIETA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_societa_deo"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_societa_deo"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "ateco_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "ateco_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivo_bilancio_deo"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivo_bilancio_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_albergo.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_veterinaria
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_deo"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_deo"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPolizzaViaggi
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza Viaggi")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivita_viaggi"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_viaggi"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) !== "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "area_partenza_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "area_partenza_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) === "RILASCIO_RESIDENZA"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "area_partenza_residenza_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "area_partenza_residenza_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) !== "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "paese_destinazione_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "paese_destinazione_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "tipologia_viaggio"
                                ) === "SHENGEN"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "paese_destinazione_shengen_viaggio"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "paese_destinazione_shengen_viaggio"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "data_partenza_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "data_partenza_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "data_rientro_viaggio"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "data_rientro_viaggio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_viaggiatori"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_viaggiatori"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Viaggiatore #1")]),
                            _c(
                              "q-list",
                              { attrs: { separator: "" } },
                              [
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.cognome1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi.cognome1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.nome1",
                                          attrs: {
                                            domanda: _vm.domande_viaggi.nome1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.cf1",
                                          attrs: {
                                            domanda: _vm.domande_viaggi.cf1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.data_nascita1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi.data_nascita1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.comune_nascita1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi
                                                .comune_nascita1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.provincia_nascita1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi
                                                .provincia_nascita1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.indirizzo1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi.indirizzo1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.comune1",
                                          attrs: {
                                            domanda: _vm.domande_viaggi.comune1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.cap1",
                                          attrs: {
                                            domanda: _vm.domande_viaggi.cap1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_viaggi.provincia1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_viaggi.provincia1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 1
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #2")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf2",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 2
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #3")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf3",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 3
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #4")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf4",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia4",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia4,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 4
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #5")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf5",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia5",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia5,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 5
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #6")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf6",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia6,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 6
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #7")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf7",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita6",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia7",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia7,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 7
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #8")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf8",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia8",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia8,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 8
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #9")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf9",
                                              attrs: {
                                                domanda: _vm.domande_viaggi.cf9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.indirizzo9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia9",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.provincia9,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_viaggiatori"
                        ) > 9
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Viaggiatore #10")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cognome10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cognome10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.nome10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.nome10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cf10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cf10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.data_nascita10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .data_nascita10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune_nascita10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .comune_nascita10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia_nascita10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia_nascita10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.indirizzo10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .indirizzo10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.comune10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.comune10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.cap10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi.cap10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_viaggi.provincia10",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_viaggi
                                                    .provincia10,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPolizzaLeasing
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Polizza Leasing")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "durata_leasing"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "durata_leasing"
                                            )
                                          )
                                        ) + " Anni"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "valore_beni_leasing"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "valore_beni_leasing"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_leasing.descrizione_beni_leasing",
                                      attrs: {
                                        domanda:
                                          _vm.domande_leasing
                                            .descrizione_beni_leasing,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_leasing.descrizione_attivita_leasing",
                                      attrs: {
                                        domanda:
                                          _vm.domande_leasing
                                            .descrizione_attivita_leasing,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_leasing.ubicazione_uguale_sede",
                                      attrs: {
                                        domanda:
                                          _vm.domande_leasing
                                            .ubicazione_uguale_sede,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.domande_leasing.ubicazione_uguale_sede
                                  .value == "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_leasing.indirizzo",
                                          attrs: {
                                            domanda:
                                              _vm.domande_leasing.indirizzo,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_leasing.ubicazione_uguale_sede.value ==
                            "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_leasing.comune_leasing",
                                          attrs: {
                                            domanda:
                                              _vm.domande_leasing
                                                .comune_leasing,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_leasing.cap_leasing",
                                          attrs: {
                                            domanda:
                                              _vm.domande_leasing.cap_leasing,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_leasing.provincia_leasing",
                                          attrs: {
                                            domanda:
                                              _vm.domande_leasing
                                                .provincia_leasing,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_leasing.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_leasing
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_leasing"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_leasing"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_leasing"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_leasing"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_leasing.data_sinistro_leasing",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_leasing
                                                      .data_sinistro_leasing,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_leasing"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_leasing.descrizione_evento_leasing",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_leasing
                                                      .descrizione_evento_leasing,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_leasing"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_leasing.importo_liquidato_leasing",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_leasing
                                                      .importo_liquidato_leasing,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isPatrimoniale
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("R.C. Patrimoniale")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_cariche_patrimoniale"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_cariche_patrimoniale"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_patrimoniale
                                        .contraente_uguale_assicurato
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_patrimoniale.contraente_uguale_assicurato",
                                          attrs: {
                                            domanda:
                                              _vm.domande_patrimoniale
                                                .contraente_uguale_assicurato,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_patrimoniale
                              .contraente_uguale_assicurato.value === "no"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.cognome",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .cognome,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.nome",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale.nome,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.codice_fiscale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .codice_fiscale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.sesso",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .sesso,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.stato_nascita_patrimoniale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .stato_nascita_patrimoniale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.data_nascita",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .data_nascita,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.domande_patrimoniale
                                      .stato_nascita_patrimoniale.value ===
                                    "ITALIA"
                                      ? _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_patrimoniale.comune_nascita_patrimoniale",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .comune_nascita_patrimoniale,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onDomandaChange(
                                                          $event
                                                        )
                                                      },
                                                    "qq-domanda-inputSelect-filter":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_patrimoniale.cap_nascita_patrimoniale",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .cap_nascita_patrimoniale,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("Domanda", {
                                                  ref: "domande_patrimoniale.provincia_nascita_patrimoniale",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .provincia_nascita_patrimoniale,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.indirizzo_patrimoniale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .indirizzo_patrimoniale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.comune_patrimoniale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .comune_patrimoniale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.cap_patrimoniale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .cap_patrimoniale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("Domanda", {
                                              ref: "domande_patrimoniale.provincia_patrimoniale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .provincia_patrimoniale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Carica #1")]),
                            _c(
                              "q-list",
                              { attrs: { separator: "" } },
                              [
                                _c(
                                  "q-item",
                                  [
                                    _vm.getRispostaAnalisiRischioFromName(
                                      "numero_cariche_patrimoniale"
                                    ) == 1
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getLabelQuestionariFromName(
                                                      "carica_svolta_patrimoniale"
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analizzaRisposta(
                                                    _vm.getDomandaQuestionariFromName(
                                                      "carica_svolta_patrimoniale"
                                                    )
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.getRispostaAnalisiRischioFromName(
                                      "numero_cariche_patrimoniale"
                                    ) > 1
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta1
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta1",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta1,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_patrimoniale
                                            .ente_appartenenza1
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_patrimoniale.ente_appartenenza1",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .ente_appartenenza1,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_patrimoniale
                                            .citta_ente_appartenenza1
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_patrimoniale.citta_ente_appartenenza1",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_patrimoniale
                                                    .citta_ente_appartenenza1,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 1
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #2")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta2
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta2",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta2,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza2
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza2",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza2,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza2
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza2",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza2,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 2
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #3")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta3
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta3",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta3,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza3
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza3",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza3,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza3
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza3",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza3,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 3
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #4")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta4
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta4",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta4,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza4
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza4",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza4,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza4
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza4",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza4,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 4
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #5")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta5
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta5",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta5,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza5
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza5",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza5,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza5
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza5",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza5,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 5
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #6")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta6
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta6",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta6,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza6
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza6",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza6,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza6
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza6",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza6,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 6
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #7")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta7
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta7",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta7,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza7
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza7",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza7,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza7
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza7",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza7,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 7
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #8")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta8
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta8",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta8,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza8
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza8",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza8,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza8
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza8",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza8,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 8
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #9")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta9
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta9",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta9,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza9
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza9",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza9,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza9
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza9",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza9,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getRispostaAnalisiRischioFromName(
                          "numero_cariche_patrimoniale"
                        ) > 9
                          ? _c(
                              "fieldset",
                              [
                                _c("legend", [_vm._v("Carica #10")]),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .carica_svolta10
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.carica_svolta10",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .carica_svolta10,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .ente_appartenenza10
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.ente_appartenenza10",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .ente_appartenenza10,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_patrimoniale
                                                .citta_ente_appartenenza10
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_patrimoniale.citta_ente_appartenenza10",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_patrimoniale
                                                        .citta_ente_appartenenza10,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomagetDomandaQuestionariFromNamendaAnalisiRischioFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_patrimoniale.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_patrimoniale
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_patrimoniale"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_patrimoniale"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_patrimoniale"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_patrimoniale"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.data_sinistro_patrimoniale",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .data_sinistro_patrimoniale,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_patrimoniale"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.descrizione_evento_patrimoniale",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .descrizione_evento_patrimoniale,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_patrimoniale"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_veterinaria.importo_liquidato_patrimoniale",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_veterinaria
                                                      .importo_liquidato_patrimoniale,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioUfficio
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Ufficio")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivita_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "superfice_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "superfice_ufficio"
                                            )
                                          )
                                        ) + " m.q."
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "anno_costruzione_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_costruzione_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "piani_fabbricato_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "piani_fabbricato_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_costruzione_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_costruzione_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_soci_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_soci_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "dipendenti_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenti_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "dipendenti_ufficio"
                                ) == "SI"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "numero_dipendenti_ufficio"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "numero_dipendenti_ufficio"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_multirischio_ufficio
                                        .ubicazione_diverso_sede
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_multirischio_ufficio.ubicazione_diverso_sede",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_ufficio
                                                .ubicazione_diverso_sede,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_ufficio
                                          .ubicazione_diverso_sede.value == "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_ufficio.indirizzo_ufficio",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_ufficio
                                                    .indirizzo_ufficio,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_ufficio
                                          .ubicazione_diverso_sede.value == "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_ufficio.comune_ufficio",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_ufficio
                                                    .comune_ufficio,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "update:domanda": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_multirischio_ufficio
                                  .ubicazione_diverso_sede.value == "si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domande_multirischio_ufficio
                                          .ubicazione_diverso_sede.value == "si"
                                          ? _c("Domanda", {
                                              ref: "domande_multirischio_ufficio.cap_ufficio",
                                              attrs: {
                                                domanda:
                                                  _vm
                                                    .domande_multirischio_ufficio
                                                    .cap_ufficio,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "provincia_ufficio"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "provincia_ufficio"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_ufficio.motivo_rifiuto_compagnia",
                                          attrs: {
                                            domanda:
                                              _vm.domande_multirischio_ufficio
                                                .motivo_rifiuto_compagnia,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_ufficio"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_ufficio"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_ufficio"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_ufficio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_ufficio.data_sinistro_ufficio",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_ufficio
                                                      .data_sinistro_ufficio,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_ufficio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_ufficio.descrizione_evento_ufficio",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_ufficio
                                                      .descrizione_evento_ufficio,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_ufficio"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_multirischio_ufficio.importo_liquidato_ufficio",
                                                attrs: {
                                                  domanda:
                                                    _vm
                                                      .domande_multirischio_ufficio
                                                      .importo_liquidato_ufficio,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isRCEdilizia
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("RC Impresa Edile")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "attivita_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_rc_edile"
                                            )
                                          )
                                        ) + " Anni"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "numero_soci_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "numero_soci_rc_edile"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "dipendenti_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "dipendenti_rc_edile"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "dipendenti_rc_edile"
                                ) == "SI"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "numero_dipendenti_rc_edile"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "numero_dipendenti_rc_edile"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "perc_lavori_subappalto_rc_edile"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "perc_lavori_subappalto_rc_edile"
                                            )
                                          )
                                        ) + " %"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.analizzaRisposta(
                                          _vm.getDomandaQuestionariFromName(
                                            "polizza_schifata"
                                          )
                                        ) === "Si"
                                          ? _c("Domanda", {
                                              ref: "domande_rc_edilizia.motivo_rifiuto_compagnia",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_rc_edilizia
                                                    .motivo_rifiuto_compagnia,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_rc_edile"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_rc_edile"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "sinistri_rc_edile"
                                  )
                                ) === "Si"
                                  ? [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_rc_edile"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_rc_edilizia.data_sinistro_edilizia",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_rc_edilizia
                                                      .data_sinistro_edilizia,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_rc_edile"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_rc_edilizia.descrizione_evento_edilizia",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_rc_edilizia
                                                      .descrizione_evento_edilizia,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_rc_edile"
                                            )
                                          ) === "Si"
                                            ? _c("Domanda", {
                                                ref: "domande_rc_edilizia.importo_liquidato_edilizia",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_rc_edilizia
                                                      .importo_liquidato_edilizia,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTCM
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Temporanea caso morte (TCM)")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "eta_tcm"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "eta_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fumatore_tcm"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fumatore_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "durata_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "durata_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "capitale_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "capitale_tcm"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_tcm
                                        .contraente_uguale_assicurato
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_tcm.contraente_uguale_assicurato",
                                          attrs: {
                                            domanda:
                                              _vm.domande_tcm
                                                .contraente_uguale_assicurato,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_tcm.altezza_tcm
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_tcm.altezza_tcm",
                                          attrs: {
                                            domanda:
                                              _vm.domande_tcm.altezza_tcm,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_tcm.peso_tcm
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_tcm.peso_tcm",
                                          attrs: {
                                            domanda: _vm.domande_tcm.peso_tcm,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.cognome
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.cognome",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.cognome,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.nome
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.nome",
                                              attrs: {
                                                domanda: _vm.domande_tcm.nome,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.data_nascita
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.data_nascita",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.data_nascita,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.codice_fiscale
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.codice_fiscale",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm
                                                    .codice_fiscale,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.sesso
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.partita_iva",
                                              attrs: {
                                                domanda: _vm.domande_tcm.sesso,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.stato_nascita_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.stato_nascita_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm
                                                    .stato_nascita_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_tcm.comune_nascita_tcm
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_tcm.comune_nascita_tcm",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_tcm
                                                        .comune_nascita_tcm,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                    "qq-domanda-change":
                                                      function ($event) {
                                                        return _vm.onDomandaChange(
                                                          $event
                                                        )
                                                      },
                                                    "qq-domanda-inputSelect-filter":
                                                      function ($event) {
                                                        return _vm.onInputSelectFilter(
                                                          $event
                                                        )
                                                      },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_tcm.cap_nascita_tcm
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_tcm.cap_nascita_tcm",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_tcm
                                                        .cap_nascita_tcm,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.domande_tcm.stato_nascita_tcm.value ==
                                    "ITALIA"
                                      ? _c(
                                          "q-item-section",
                                          [
                                            _vm.domandaIsVisible(
                                              _vm.domande_tcm
                                                .provincia_nascita_tcm
                                            )
                                              ? _c("Domanda", {
                                                  ref: "domande_tcm.provincia_nascita_tcm",
                                                  attrs: {
                                                    domanda:
                                                      _vm.domande_tcm
                                                        .provincia_nascita_tcm,
                                                  },
                                                  on: {
                                                    "update:isValid":
                                                      _vm.onDomandaValidated,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.domande_tcm.contraente_uguale_assicurato
                              .value == "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.indirizzo_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.indirizzo_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.indirizzo_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.comune_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.comune_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.comune_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "qq-domanda-change": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.cap_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.cap_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.cap_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.provincia_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.provincia_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm.provincia_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "attivita_rischio_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "attivita_rischio_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.getRispostaAnalisiRischioFromName(
                                  "attivita_rischio_tcm"
                                ) == "NO"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "attivita_tcm"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "attivita_tcm"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("QUESTIONARIO ANAMNESTICO “A”")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "interrompe_lavoro_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "interrompe_lavoro_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "ricoveri_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "ricoveri_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "malattie_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "malattie_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "patologie_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "patologie_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "altro_tcm"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "altro_tcm"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm.isQuestionarioBTcmAbilitato
                          ? [
                              _c("legend", [
                                _vm._v("QUESTIONARIO ANAMNESTICO “B”"),
                              ]),
                              _c(
                                "q-list",
                                { attrs: { separator: "" } },
                                [
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm.attesa_ricovero_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.attesa_ricovero_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm
                                                      .attesa_ricovero_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm
                                              .esami_diagnostici_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.esami_diagnostici_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm
                                                      .esami_diagnostici_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm
                                              .malattie_terapia_medica_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.malattie_terapia_medica_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm
                                                      .malattie_terapia_medica_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm
                                              .attivita_sportive_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.attivita_sportive_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm
                                                      .attivita_sportive_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm
                                              .sostanze_stupefacenti_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.sostanze_stupefacenti_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm
                                                      .sostanze_stupefacenti_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _vm.domandaIsVisible(
                                            _vm.domande_tcm.inabile_tcm
                                          )
                                            ? _c("Domanda", {
                                                ref: "domande_tcm.inabile_tcm",
                                                attrs: {
                                                  domanda:
                                                    _vm.domande_tcm.inabile_tcm,
                                                },
                                                on: {
                                                  "update:isValid":
                                                    _vm.onDomandaValidated,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c("legend", [_vm._v("DESIGNAZIONE DEI BENEFICIARI")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_tcm.tipo_beneficiario_tcm
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_tcm.tipo_beneficiario_tcm",
                                          attrs: {
                                            domanda:
                                              _vm.domande_tcm
                                                .tipo_beneficiario_tcm,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.domande_tcm.tipo_beneficiario_tcm.value ==
                                "elenco_beneficiari"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_tcm.totale_beneficiari_tcm
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_tcm.totale_beneficiari_tcm",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_tcm
                                                    .totale_beneficiari_tcm,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_tcm.tipo_beneficiario_tcm.value ==
                            "elenco_beneficiari"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-list",
                                          { attrs: { bordered: "" } },
                                          [
                                            _c(
                                              "q-item",
                                              [
                                                _c("q-item-section", [
                                                  _vm._v(
                                                    "Inserisci i dati dei Beneficiari"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item",
                                              [
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .cognome_nome1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.cognome_nome1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .cognome_nome1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .data_nascita1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.data_nascita1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .data_nascita1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .luogo_nascita1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.luogo_nascita1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .luogo_nascita1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .residenza1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.residenza1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .residenza1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .relazione1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.relazione1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .relazione1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _vm.domandaIsVisible(
                                                      _vm.domande_tcm
                                                        .percentuale_ripartizione1_tcm
                                                    )
                                                      ? _c("Domanda", {
                                                          ref: "domande_tcm.percentuale_ripartizione1_tcm",
                                                          attrs: {
                                                            domanda:
                                                              _vm.domande_tcm
                                                                .percentuale_ripartizione1_tcm,
                                                          },
                                                          on: {
                                                            "update:isValid":
                                                              _vm.onDomandaValidated,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 1
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .cognome_nome2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.cognome_nome2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .cognome_nome2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .data_nascita2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.data_nascita2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .data_nascita2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .luogo_nascita2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.luogo_nascita2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .luogo_nascita2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .residenza2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.residenza2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .residenza2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .relazione2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.relazione2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .relazione2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .percentuale_ripartizione2_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.percentuale_ripartizione2_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .percentuale_ripartizione2_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 2
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .cognome_nome3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.cognome_nome3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .cognome_nome3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .data_nascita3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.data_nascita3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .data_nascita3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .luogo_nascita3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.luogo_nascita3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .luogo_nascita3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .residenza3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.residenza3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .residenza3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .relazione3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.relazione3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .relazione3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .percentuale_ripartizione3_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.percentuale_ripartizione3_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .percentuale_ripartizione3_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 3
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .cognome_nome4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.cognome_nome4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .cognome_nome4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .data_nascita4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.data_nascita4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .data_nascita4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .luogo_nascita4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.luogo_nascita4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .luogo_nascita4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .residenza4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.residenza4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .residenza4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .relazione4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.relazione4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .relazione4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .percentuale_ripartizione4_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.percentuale_ripartizione4_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .percentuale_ripartizione4_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            parseInt(
                                              _vm.domande_tcm
                                                .totale_beneficiari_tcm.value
                                            ) > 4
                                              ? _c(
                                                  "q-item",
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .cognome_nome5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.cognome_nome5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .cognome_nome5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .data_nascita5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.data_nascita5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .data_nascita5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .luogo_nascita5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.luogo_nascita5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .luogo_nascita5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .residenza5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.residenza5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .residenza5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .relazione5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.relazione5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .relazione5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _vm.domandaIsVisible(
                                                          _vm.domande_tcm
                                                            .percentuale_ripartizione5_tcm
                                                        )
                                                          ? _c("Domanda", {
                                                              ref: "domande_tcm.percentuale_ripartizione5_tcm",
                                                              attrs: {
                                                                domanda:
                                                                  _vm
                                                                    .domande_tcm
                                                                    .percentuale_ripartizione5_tcm,
                                                              },
                                                              on: {
                                                                "update:isValid":
                                                                  _vm.onDomandaValidated,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("br"),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.isTutelaLegaleMedici
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Tutela legale settore Medico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "specializzazione_tl_medici"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "specializzazione_tl_medici"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.analizzaRisposta(
                                          _vm.getDomandaQuestionariFromName(
                                            "polizza_schifata"
                                          )
                                        ) === "Si"
                                          ? _c("Domanda", {
                                              ref: "domande_rc_edilizia.motivo_rifiuto_compagnia",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_rc_edilizia
                                                    .motivo_rifiuto_compagnia,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_tutela_medico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_tutela_medico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isTutelaLegaleTecnici
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Tutela legale settore Tecnico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.analizzaRisposta(
                              _vm.getDomandaQuestionariFromName(
                                "polizza_in_corso"
                              )
                            ) === "Si"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_schifata"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_schifata"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.analizzaRisposta(
                                          _vm.getDomandaQuestionariFromName(
                                            "polizza_schifata"
                                          )
                                        ) === "Si"
                                          ? _c("Domanda", {
                                              ref: "domande_rc_edilizia.motivo_rifiuto_compagnia",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_rc_edilizia
                                                    .motivo_rifiuto_compagnia,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_tutela_tecnico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_tutela_tecnico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isCauzioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Cauzioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_rischio_cauzioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_rischio_cauzioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isAsseverazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Asseverazioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_rischio_asseverazioni"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_rischio_asseverazioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isFideiussioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Fideiussioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_rischio_fidejussione"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_rischio_fidejussione"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isFotovoltaico
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("All Risk Fotovoltaico")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "costo_impianto_fotovoltaico"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "costo_impianto_fotovoltaico"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "estensioni_fotovoltaico"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.estensioni_fotovoltaico,
                                          function (estensione, index) {
                                            return _c("li", { key: index }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(estensione.label) +
                                                  " "
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_fotovoltaico.indirizzo_fotovoltaico",
                                      attrs: {
                                        domanda:
                                          _vm.domande_fotovoltaico
                                            .indirizzo_fotovoltaico,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_fotovoltaico.comune_fotovoltaico",
                                      attrs: {
                                        domanda:
                                          _vm.domande_fotovoltaico
                                            .comune_fotovoltaico,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                        "update:domanda": function ($event) {
                                          return _vm.onDomandaChange($event)
                                        },
                                        "qq-domanda-change": function ($event) {
                                          return _vm.onDomandaChange($event)
                                        },
                                        "qq-domanda-inputSelect-filter":
                                          function ($event) {
                                            return _vm.onInputSelectFilter(
                                              $event
                                            )
                                          },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_fotovoltaico.cap_fotovoltaico",
                                      attrs: {
                                        domanda:
                                          _vm.domande_fotovoltaico
                                            .cap_fotovoltaico,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_fotovoltaico.provincia_fotovoltaico",
                                      attrs: {
                                        domanda:
                                          _vm.domande_fotovoltaico
                                            .provincia_fotovoltaico,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_fotovoltaico
                                        .tipologia_fotovoltaico
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_fotovoltaico.tipologia_fotovoltaico",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .tipologia_fotovoltaico,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_fotovoltaico
                                        .tipo_installazione
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_fotovoltaico.tipo_installazione",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .tipo_installazione,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.domande_fotovoltaico.tipo_installazione
                                  .value === "tetto"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_fotovoltaico.altezza_impianto",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .altezza_impianto,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _vm.domande_fotovoltaico.tipo_installazione
                                  .value === "tetto"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_fotovoltaico.numero_piani",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .numero_piani,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_fotovoltaico.numero_inverter
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_fotovoltaico.numero_inverter",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .numero_inverter,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _vm.domandaIsVisible(
                                      _vm.domande_fotovoltaico.numero_pannelli1
                                    )
                                      ? _c("Domanda", {
                                          ref: "domande_fotovoltaico.numero_pannelli1",
                                          attrs: {
                                            domanda:
                                              _vm.domande_fotovoltaico
                                                .numero_pannelli1,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.domande_fotovoltaico.numero_inverter
                                  .value >= 2
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_fotovoltaico
                                            .numero_pannelli2
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_fotovoltaico.numero_pannelli2",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_fotovoltaico
                                                    .numero_pannelli2,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domande_fotovoltaico.numero_inverter
                                  .value >= 3
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_fotovoltaico
                                            .numero_pannelli3
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_fotovoltaico.numero_pannelli3",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_fotovoltaico
                                                    .numero_pannelli3,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "polizza_in_corso"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_in_corso"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "compagnia"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.analizzaRisposta(
                                  _vm.getDomandaQuestionariFromName(
                                    "polizza_in_corso"
                                  )
                                ) === "Si"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              "Data di scadenza dell`ultima copertura assicurativa?"
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "scadenza_copertura"
                                                )
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_fotovoltaico"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_fotovoltaico"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isDonazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Donazioni Immobili")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_donazione"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_donazione"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "valore_donazione"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "valore_donazione"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isDroni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("SARP Droni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "totale_droni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "totale_droni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "costruttore_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "costruttore_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "modello_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "modello_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "anno_costruzione_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "anno_costruzione_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "peso_drone_1"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "peso_drone_1"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.totale_droni >= 2
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_2"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_2"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 3
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_3"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_3"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 4
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_4"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_4"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 5
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_5"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_5"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 6
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_6"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_6"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 7
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_7"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_7"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 8
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_8"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_8"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni >= 9
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_9"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_9"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.totale_droni === 10
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "costruttore_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "costruttore_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "modello_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "modello_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "anno_costruzione_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "anno_costruzione_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipologia_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipologia_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "peso_drone_10"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "peso_drone_10"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_droni"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_droni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isCatastrofali
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Rischi Catastrofali")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_fabbricato_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_fabbricato_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.domandaIsVisible(
                                  _vm.getDomandaQuestionariFromName(
                                    "tipo_dimora_catastrofali"
                                  )
                                )
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipo_dimora_catastrofali"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipo_dimora_catastrofali"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.domandaIsVisible(
                                  _vm.getDomandaQuestionariFromName(
                                    "tipo_fabbricato_catastrofali"
                                  )
                                )
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "tipo_fabbricato_catastrofali"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "tipo_fabbricato_catastrofali"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_abitazione_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_abitazione_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_costruzione_catastrofali"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_costruzione_catastrofali"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "metri_quadri_catastrofale"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "metri_quadri_catastrofale"
                                            )
                                          )
                                        ) + " mq"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_catastrofali.piani_interrati",
                                      attrs: {
                                        domanda:
                                          _vm.domande_catastrofali
                                            .piani_interrati,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_catastrofali.piani_fuori_terra",
                                      attrs: {
                                        domanda:
                                          _vm.domande_catastrofali
                                            .piani_fuori_terra,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_catastrofali.indirizzo_catastrofali",
                                          attrs: {
                                            domanda:
                                              _vm.domande_catastrofali
                                                .indirizzo_catastrofali,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_catastrofali
                                            .comune_catastrofali
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_catastrofali.comune_catastrofali",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_catastrofali
                                                    .comune_catastrofali,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                                "update:domanda": function (
                                                  $event
                                                ) {
                                                  return _vm.onDomandaChange(
                                                    $event
                                                  )
                                                },
                                                "qq-domanda-inputSelect-filter":
                                                  function ($event) {
                                                    return _vm.onInputSelectFilter(
                                                      $event
                                                    )
                                                  },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      [
                                        _vm.domandaIsVisible(
                                          _vm.domande_catastrofali
                                            .cap_catastrofali
                                        )
                                          ? _c("Domanda", {
                                              ref: "domande_catastrofali.cap_catastrofali",
                                              attrs: {
                                                domanda:
                                                  _vm.domande_catastrofali
                                                    .cap_catastrofali,
                                              },
                                              on: {
                                                "update:isValid":
                                                  _vm.onDomandaValidated,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "provincia_catastrofali"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "provincia_catastrofali"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabelQuestionariFromName(
                                        "sinistri_catastrofali"
                                      )
                                    ) + " "
                                  ),
                                ]),
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.analizzaRisposta(
                                        _vm.getDomandaQuestionariFromName(
                                          "sinistri_catastrofali"
                                        )
                                      )
                                    ) + " mq"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isStruttureSanitarie
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Strutture Sanitarie")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_struttura_sanitaria"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_struttura_sanitaria"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "struttura_sanitaria_convenzionata"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "struttura_sanitaria_convenzionata"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_strutture_sanitarie.data_apertura_sanitarie",
                                      attrs: {
                                        domanda:
                                          _vm.domande_strutture_sanitarie
                                            .data_apertura_sanitarie,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie",
                                      attrs: {
                                        domanda:
                                          _vm.domande_strutture_sanitarie
                                            .ubicazione_uguale_sede_sanitarie,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.domande_strutture_sanitarie
                                  .ubicazione_uguale_sede_sanitarie.value ===
                                "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_strutture_sanitarie.indirizzo_ubicazione_sanitarie",
                                          attrs: {
                                            domanda:
                                              _vm.domande_strutture_sanitarie
                                                .indirizzo_ubicazione_sanitarie,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_strutture_sanitarie
                              .ubicazione_uguale_sede_sanitarie.value === "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_strutture_sanitarie.comune_ubicazione_sanitarie",
                                          attrs: {
                                            domanda:
                                              _vm.domande_strutture_sanitarie
                                                .comune_ubicazione_sanitarie,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "update:domanda": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_strutture_sanitarie.cap_ubicazione_sanitarie",
                                          attrs: {
                                            domanda:
                                              _vm.domande_strutture_sanitarie
                                                .cap_ubicazione_sanitarie,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_strutture_sanitarie.provincia_ubicazione_sanitarie",
                                          attrs: {
                                            domanda:
                                              _vm.domande_strutture_sanitarie
                                                .provincia_ubicazione_sanitarie,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(2),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                        attrs: { caption: "" },
                                      },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCompagniaSelezionata
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive,
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                            attrs: { caption: "" },
                                          },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.analizzaRisposta(garanzia)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_struttura_sanitaria"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_struttura_sanitaria"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isShengen
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Cauzioni Shengen")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "paese_partenza_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "paese_partenza_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "data_partenza_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "data_partenza_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "paese_destinazione_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "paese_destinazione_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "data_rientro_shengen"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "data_rientro_shengen"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [
                          _vm._v("Informazioni sulla persona ospitata"),
                        ]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_shengen.cognome_shengen",
                                      attrs: {
                                        domanda:
                                          _vm.domande_shengen.cognome_shengen,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_shengen.nome_shengen",
                                      attrs: {
                                        domanda:
                                          _vm.domande_shengen.nome_shengen,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_shengen.data_nascita_shengen",
                                      attrs: {
                                        domanda:
                                          _vm.domande_shengen
                                            .data_nascita_shengen,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_shengen.numero_passaporto_shengen",
                                      attrs: {
                                        domanda:
                                          _vm.domande_shengen
                                            .numero_passaporto_shengen,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(3),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                        attrs: { caption: "" },
                                      },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCompagniaSelezionata
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive,
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                            attrs: { caption: "" },
                                          },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.analizzaRisposta(garanzia)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                      ],
                      1
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.isAssociazioni
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Associazioni")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "segmento_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "segmento_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "servizi_svolti_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "servizi_svolti_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipologia_ente_associazioni"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipologia_ente_associazioni"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_associazioni.numero_associati_associazioni",
                                      attrs: {
                                        domanda:
                                          _vm.domande_associazioni
                                            .numero_associati_associazioni,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_associazioni.numero_volontari_associazioni",
                                      attrs: {
                                        domanda:
                                          _vm.domande_associazioni
                                            .numero_volontari_associazioni,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_associazioni.numero_dipendenti_associazioni",
                                      attrs: {
                                        domanda:
                                          _vm.domande_associazioni
                                            .numero_dipendenti_associazioni,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _vm._m(4),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                        attrs: { caption: "" },
                                      },
                                      [_vm._v("Compagnia")]
                                    ),
                                    _c(
                                      "q-item-label",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isTaylorMade,
                                            expression: "!isTaylorMade",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCompagniaSelezionata
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.getElencoGaranzieAggiuntive,
                              function (garanzia, index) {
                                return _c(
                                  "q-item",
                                  { key: index },
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                            attrs: { caption: "" },
                                          },
                                          [_vm._v(_vm._s(garanzia.label))]
                                        ),
                                        _c(
                                          "q-item-label",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isTaylorMade,
                                                expression: "!isTaylorMade",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.analizzaRisposta(garanzia)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_struttura_associazioni"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_struttura_associazioni"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.isMultirischioAgricoltura
                ? _c("div", [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Multirischio Agricoltura")]),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "fatturato"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "fatturato"
                                            )
                                          )
                                        ) + " Euro"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "tipo_fabbricato_agricoltura"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "tipo_fabbricato_agricoltura"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLabelQuestionariFromName(
                                              "materiale_fabbricato_agricoltura"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "materiale_fabbricato_agricoltura"
                                            )
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.tipologia_contraente_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .tipologia_contraente_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.valutazione_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .valutazione_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.allarme_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .allarme_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.numero_post_letto_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .numero_post_letto_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.anno_costruzione_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .anno_costruzione_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.superficie_mq_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .superficie_mq_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.superficie_ettari_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .superficie_ettari_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.stabilmente_abitata_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .stabilmente_abitata_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.numero_soci_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .numero_soci_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.numero_dipendenti_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .numero_dipendenti_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("Domanda", {
                                      ref: "domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura",
                                      attrs: {
                                        domanda:
                                          _vm.domande_multirischio_agricoltura
                                            .ubicazione_uguale_sede_agricoltura,
                                      },
                                      on: {
                                        "update:isValid":
                                          _vm.onDomandaValidated,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.domande_multirischio_agricoltura
                                  .ubicazione_uguale_sede_agricoltura.value ===
                                "no"
                                  ? _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_agricoltura.indirizzo_ubicazione_agricoltura",
                                          attrs: {
                                            domanda:
                                              _vm
                                                .domande_multirischio_agricoltura
                                                .indirizzo_ubicazione_agricoltura,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.domande_multirischio_agricoltura
                              .ubicazione_uguale_sede_agricoltura.value === "no"
                              ? _c(
                                  "q-item",
                                  [
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_agricoltura.comune_ubicazione_agricoltura",
                                          attrs: {
                                            domanda:
                                              _vm
                                                .domande_multirischio_agricoltura
                                                .comune_ubicazione_agricoltura,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                            "update:domanda": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-change": function (
                                              $event
                                            ) {
                                              return _vm.onDomandaChange($event)
                                            },
                                            "qq-domanda-inputSelect-filter":
                                              function ($event) {
                                                return _vm.onInputSelectFilter(
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("Domanda", {
                                          ref: "domande_multirischio_agricoltura.cap_ubicazione_agricoltura",
                                          attrs: {
                                            domanda:
                                              _vm
                                                .domande_multirischio_agricoltura
                                                .cap_ubicazione_agricoltura,
                                          },
                                          on: {
                                            "update:isValid":
                                              _vm.onDomandaValidated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getLabelQuestionariFromName(
                                                  "provincia_agricoltura"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("q-item-label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.analizzaRisposta(
                                                _vm.getDomandaQuestionariFromName(
                                                  "provincia_agricoltura"
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("legend", [_vm._v("Situazione assicurativa")]),
                        _c(
                          "q-list",
                          { attrs: { separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "" } },
                                      [
                                        _vm._v(
                                          "Hai mai stipulato una polizza per questo rischio?"
                                        ),
                                      ]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.getRispostaAnalisiRischioFromName(
                                            "polizza_in_corso"
                                          ) === "SI",
                                        expression:
                                          "getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Impresa di assicurazione"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "compagnia"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  "Data di scadenza della copertura"
                                                ),
                                              ]
                                            ),
                                            _c("q-item-label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRispostaAnalisiRischioFromName(
                                                    "scadenza_copertura"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", {
                                      attrs: { caption: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLabelQuestionariFromName(
                                            "sinistri_struttura_agricoltura"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.analizzaRisposta(
                                            _vm.getDomandaQuestionariFromName(
                                              "sinistri_struttura_agricoltura"
                                            )
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Informazioni generali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }