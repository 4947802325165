var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("br"),
        _c("div", { attrs: { align: "center" } }, [
          _c("span", { staticClass: "text-h4" }, [
            _vm._v("Questionario assuntivo " + _vm._s(_vm.tipo_questionario)),
          ]),
          _c("br"),
          _c("strong", [
            _vm._v(
              "Completa il questionario sotto con le informazioni mancanti"
            ),
          ]),
          _c("hr"),
        ]),
        _c("br"),
        _c("br"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.is_loading,
                expression: "is_loading",
              },
            ],
            staticClass:
              "text-center justify-center q-pa-md q-gutter-sm fixed-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _vm.isRcTecnico
          ? _c(
              "div",
              [
                _vm.isRcTecnico
                  ? _c("QQQuestionarioRCTecnico", {
                      ref: "questionario",
                      attrs: {
                        indice_questionario_rischio: _vm.indice_prodotto,
                      },
                      on: { "update:isValid": _vm.onIsValid },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.isRcMedico
          ? _c(
              "div",
              [
                _vm.isRcMedico
                  ? _c("QQQuestionarioRCMedico", {
                      ref: "questionario",
                      attrs: {
                        indice_questionario_rischio: _vm.indice_prodotto,
                      },
                      on: { "update:isValid": _vm.onIsValid },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.isRcSanitario
          ? _c(
              "div",
              [
                _vm.isRcSanitario
                  ? _c("QQQuestionarioRCSanitario", {
                      ref: "questionario",
                      attrs: {
                        indice_questionario_rischio: _vm.indice_prodotto,
                      },
                      on: { "update:isValid": _vm.onIsValid },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.isAltroRamo
          ? _c(
              "div",
              [
                _c("QQQuestionarioPersoneAziende", {
                  ref: "questionario",
                  attrs: { indice_questionario_rischio: _vm.indice_prodotto },
                  on: { "update:isValid": _vm.onIsValid },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("hr"),
        _c("div", { staticClass: "row", attrs: { align: "center" } }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onIndietroClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "ANNULLA EMISSIONE",
                  color: "blue-grey",
                  icon: "mdi-close-octagon",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.gotoStart()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: _vm.label_quotazione,
                  color: "blue-grey",
                  icon: "mdi-arrow-right-bold",
                  size: "md",
                  icon_align: "right",
                  disabled: _vm.is_loading,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onAvantiClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }