var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.domande_caricate
    ? _c(
        "div",
        {
          staticClass: "row justify-center",
          on: {
            input: function ($event) {
              return _vm.resultDomande()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "col-12 col-md-10", attrs: { align: "left" } },
            [
              _vm._m(0),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Cognome, Nome / Ragione Sociale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(_vm.getDatiCliente("nominativo"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Indirizzo"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("indirizzo"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Civico"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("civico"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("comune"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiResidenzaCliente("provincia"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("CAP"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(_vm.getDatiResidenzaCliente("cap"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Codice Fiscale"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("codice_fiscale"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Partita IVA"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(_vm.getDatiCliente("partita_iva"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Comune di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("comune_nascita"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Provincia di nascita"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(_vm.getDatiCliente("provincia_nascita"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Attività svolta"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getRispostaQuestionariFromName("lavoro")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Tipo Attività"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getRispostaQuestionariFromName(
                                  "tipo_lavoro"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.isSindacoRevisore
                        ? _c(
                            "q-item-section",
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v("Svolgi attività di Sindaco Revisore?"),
                              ]),
                              _c("q-item-label", [_vm._v("SI")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSindacoRevisore
                        ? _c(
                            "q-item-section",
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(
                                  "A quale percentuale corrisponde il fatturato di Sindaco/Revisore?"
                                ),
                              ]),
                              _c("q-item-label", [
                                _vm._v(
                                  _vm._s(_vm.getPercentualeSindacoRevisore)
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getRispostaQuestionariFromName(
                                  "iscrizione_albo"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm._m(1),
              _c(
                "q-list",
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(_vm.domande.data_inizio_attivita)
                            ? _c("Domanda", {
                                ref: "domande.data_inizio_attivita",
                                attrs: {
                                  domanda: _vm.domande.data_inizio_attivita,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(_vm.domande.data_iscrizione_albo)
                            ? _c("Domanda", {
                                ref: "domande.data_iscrizione_albo",
                                attrs: {
                                  domanda: _vm.domande.data_iscrizione_albo,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.numero_iscrizione_albo
                              )
                                ? _c("Domanda", {
                                    ref: "domande.numero_iscrizione_albo",
                                    attrs: {
                                      domanda:
                                        _vm.domande.numero_iscrizione_albo,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            [
                              _vm.domandaIsVisible(_vm.domande.sezione_albo)
                                ? _c("Domanda", {
                                    ref: "domande.sezione_albo",
                                    attrs: {
                                      domanda: _vm.domande.sezione_albo,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(
                            _vm.domande.data_ultimo_aggiornamento_prof
                          )
                            ? _c("Domanda", {
                                ref: "domande.data_ultimo_aggiornamento_prof",
                                attrs: {
                                  domanda:
                                    _vm.domande.data_ultimo_aggiornamento_prof,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.numero_crediti_conseguiti
                              )
                                ? _c("Domanda", {
                                    ref: "domande.numero_crediti_conseguiti",
                                    attrs: {
                                      domanda:
                                        _vm.domande.numero_crediti_conseguiti,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm._m(2),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isTaylorMade,
                                  expression: "!isTaylorMade",
                                },
                              ],
                              attrs: { caption: "" },
                            },
                            [_vm._v("Compagnia")]
                          ),
                          _c(
                            "q-item-label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isTaylorMade,
                                  expression: "!isTaylorMade",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.getCompagniaSelezionata) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.getElencoGaranzieAggiuntive,
                    function (garanzia, index) {
                      return _c(
                        "q-item",
                        { key: index },
                        [
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isTaylorMade,
                                      expression: "!isTaylorMade",
                                    },
                                  ],
                                  attrs: { caption: "" },
                                },
                                [_vm._v(_vm._s(garanzia.label))]
                              ),
                              _c(
                                "q-item-label",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isTaylorMade,
                                      expression: "!isTaylorMade",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.analizzaRisposta(garanzia)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(_vm.domande.chiude_attivita)
                            ? _c("Domanda", {
                                ref: "domande.chiude_attivita",
                                attrs: { domanda: _vm.domande.chiude_attivita },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(_vm.domande.vuole_postuma)
                            ? _c("Domanda", {
                                ref: "domande.vuole_postuma",
                                attrs: { domanda: _vm.domande.vuole_postuma },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("br"),
              _vm._m(3),
              _c(
                "q-list",
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.fatturato.fatturato_corrente
                              )
                                ? _c("Domanda", {
                                    ref: "domande.fatturato.fatturato_corrente",
                                    attrs: {
                                      domanda:
                                        _vm.domande.fatturato
                                          .fatturato_corrente,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Fatturato consuntivo " +
                                _vm._s(new Date().getFullYear() - 1)
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              _vm._s(
                                _vm.getRispostaQuestionariFromName("fatturato")
                              ) + " Euro"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(
                            _vm.domande.fatturato.fatturato_due_anni_fa
                          )
                            ? _c("Domanda", {
                                ref: "domande.fatturato.fatturato_due_anni_fa",
                                attrs: {
                                  domanda:
                                    _vm.domande.fatturato.fatturato_due_anni_fa,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _vm.domandaIsVisible(
                            _vm.domande.fatturato.fatturato_tre_anni_fa
                          )
                            ? _c("Domanda", {
                                ref: "domande.fatturato.fatturato_tre_anni_fa",
                                attrs: {
                                  domanda:
                                    _vm.domande.fatturato.fatturato_tre_anni_fa,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Hai un Cliente su cui fatturi piu` del 50% del fatturato?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.rispostaClientePiu50Fatturato) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.haveClientePiu50Fatturato,
                          expression: "haveClientePiu50Fatturato",
                        },
                      ],
                    },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.cliente_50_percento.nominativo
                              )
                                ? _c("Domanda", {
                                    ref: "domande.cliente_50_percento.nominativo",
                                    attrs: {
                                      domanda:
                                        _vm.domande.cliente_50_percento
                                          .nominativo,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.cliente_50_percento.settore_attivita
                              )
                                ? _c("Domanda", {
                                    ref: "domande.cliente_50_percento.settore_attivita",
                                    attrs: {
                                      domanda:
                                        _vm.domande.cliente_50_percento
                                          .settore_attivita,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.cliente_50_percento.altro
                              )
                                ? _c("Domanda", {
                                    ref: "domande.cliente_50_percento.altro",
                                    attrs: {
                                      domanda:
                                        _vm.domande.cliente_50_percento.altro,
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _vm.domandaIsVisible(
                                _vm.domande.cliente_50_percento.perc_fatturato
                              )
                                ? _c("Domanda", {
                                    ref: "domande.cliente_50_percento.perc_fatturato",
                                    attrs: {
                                      domanda:
                                        _vm.domande.cliente_50_percento
                                          .perc_fatturato,
                                      riferimento: _vm.getImportoPrecedente(
                                        _vm.domande.cliente_50_percento
                                          .perc_fatturato.value
                                      ),
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm._m(4),
              _c(
                "q-list",
                { attrs: { bordered: "", separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _c("strong", [_vm._v("ATTIVITÀ")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _c("strong", [
                              _vm._v(
                                "Suddivisione Percentuale Fatturato Anno precedente"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _c("strong", [
                              _vm._v(
                                "Suddivisione Percentuale Fatturato Anno corrente"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _c("strong", [_vm._v("Attivita ordinaria")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.percentuale_fatturato_attivita_ordinaria_anno_precedente()
                                ) +
                                " % "
                            ),
                          ]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getImportoPrecedente(
                                  _vm.percentuale_fatturato_attivita_ordinaria_anno_precedente()
                                )
                              ) + " Euro"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.percentuale_fatturato_attivita_ordinaria_anno_corrente()
                                ) +
                                " % "
                            ),
                          ]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getImportoCorrente(
                                  _vm.percentuale_fatturato_attivita_ordinaria_anno_corrente()
                                )
                              ) + " Euro"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.domande.distribuzione_fatturato,
                    function (attivita, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm.domandaIsVisible(attivita.perc_anno_corrente)
                            ? _c(
                                "q-item",
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(_vm._s(attivita.label.label)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("Domanda", {
                                        ref:
                                          "domande.distribuzione_fatturato." +
                                          index +
                                          ".perc_anno_precedente",
                                        refInFor: true,
                                        attrs: {
                                          domanda:
                                            attivita.perc_anno_precedente,
                                          riferimento: _vm.getImportoPrecedente(
                                            attivita.perc_anno_precedente.value
                                          ),
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("Domanda", {
                                        ref:
                                          "domande.distribuzione_fatturato." +
                                          index +
                                          ".perc_anno_corrente",
                                        refInFor: true,
                                        attrs: {
                                          domanda: attivita.perc_anno_corrente,
                                          riferimento: _vm.getImportoCorrente(
                                            attivita.perc_anno_corrente.value
                                          ),
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFatturatoPrecedenteCorretto,
                      expression: "!isFatturatoPrecedenteCorretto",
                    },
                  ],
                },
                [
                  _c("strong", { staticStyle: { color: "#FF0000" } }, [
                    _vm._v("ERRORE"),
                  ]),
                  _vm._v(
                    " Nel fatturato anno precedente sono presenti percentuali a zero o la somma delle percentuali è diversa da 100 "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFatturatoCorrenteCorretto,
                      expression: "!isFatturatoCorrenteCorretto",
                    },
                  ],
                },
                [
                  _c("strong", { staticStyle: { color: "#FF0000" } }, [
                    _vm._v("ERRORE"),
                  ]),
                  _vm._v(
                    " Nel fatturato anno corrente sono presenti percentuali a zero o la somma delle percentuali è diversa da 100 "
                  ),
                ]
              ),
              _c("br"),
              _c("strong", [_vm._v("NOTA BENE")]),
              _vm._v(
                ": Le percentuali del fatturato vanno inserite su tutti i campi "
              ),
              _c("br"),
              _c("br"),
              _vm._m(5),
              _c(
                "q-list",
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v("Percentuale fatturato Italia"),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.percentualeFatturatoItalia) +
                                " % "
                            ),
                          ]),
                          _c(
                            "q-item-label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.percentualeFatturatoItalia < 0,
                                  expression: "percentualeFatturatoItalia < 0",
                                },
                              ],
                              staticStyle: { color: "#ff0000" },
                            },
                            [_vm._v("La percentuale e` inferiore a 100")]
                          ),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getImportoFatturatoEstero(
                                  _vm.percentualeFatturatoItalia
                                )
                              ) + " Euro"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("Domanda", {
                            ref: "domande.fatturato_estero.perc_fatturato_ue",
                            attrs: {
                              domanda:
                                _vm.domande.fatturato_estero.perc_fatturato_ue,
                              riferimento: _vm.getImportoFatturatoEstero(
                                _vm.domande.fatturato_estero.perc_fatturato_ue
                                  .value
                              ),
                            },
                            on: { "update:isValid": _vm.onDomandaValidated },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("Domanda", {
                            ref: "domande.fatturato_estero.perc_fatturato_non_ue",
                            attrs: {
                              domanda:
                                _vm.domande.fatturato_estero
                                  .perc_fatturato_non_ue,
                              riferimento: _vm.getImportoFatturatoEstero(
                                _vm.domande.fatturato_estero
                                  .perc_fatturato_non_ue.value
                              ),
                            },
                            on: { "update:isValid": _vm.onDomandaValidated },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.lavoraUsaCanada,
                              expression: "lavoraUsaCanada",
                            },
                          ],
                        },
                        [
                          _c("Domanda", {
                            ref: "domande.fatturato_estero.perc_fatturato_usa_canada",
                            attrs: {
                              domanda:
                                _vm.domande.fatturato_estero
                                  .perc_fatturato_usa_canada,
                              riferimento: _vm.getImportoFatturatoEstero(
                                _vm.domande.fatturato_estero
                                  .perc_fatturato_usa_canada.value
                              ),
                            },
                            on: { "update:isValid": _vm.onDomandaValidated },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ha_funzioni_pubbliche,
                      expression: "ha_funzioni_pubbliche",
                    },
                  ],
                },
                [
                  _vm._m(6),
                  _c(
                    "q-list",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _vm._v(
                                  "Si prega di specificare le funzioni ricoperte dall'Assicurato e le relative anzianità"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("DESCRIZIONE")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("DATA INIZIO ATTIVITÀ")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        Object.values(_vm.domande.funzioni_pubbliche),
                        function (dato, index) {
                          return _c(
                            "q-item",
                            { key: index, staticClass: "q-mx-sm q-px-sm" },
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.funzioni_pubbliche.descrizione" +
                                      (index + 1) +
                                      ".funzioni_pubbliche.funzioni_pubbliche" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "funz_pubbliche_descrizione" +
                                            (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.funzioni_pubbliche.datadal" +
                                      (index + 1) +
                                      ".funzioni_pubbliche.funzioni_pubbliche" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "funz_pubbliche_datadal" + (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("br"),
              _c("Domanda", {
                ref: "domande.dati_consigliere_amministrazione",
                attrs: {
                  domanda: _vm.domande.dati_consigliere_amministrazione,
                },
              }),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSindacoRevisore,
                      expression: "isSindacoRevisore",
                    },
                  ],
                },
                [
                  _vm._m(7),
                  _c(
                    "q-list",
                    { attrs: { bordered: "" } },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("SOCIETÀ")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("Capitale sociale")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("Fatturato")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("Settore attività")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("Ruolo")]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [
                                  _vm._v(
                                    "Società o controllante quotate in borsa"
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", [
                                _c("strong", [_vm._v("Data nomina")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        Object.values(_vm.domande.dati_sindaco),
                        function (dato, index) {
                          return _c(
                            "q-item",
                            { key: index, staticClass: "q-mx-sm q-px-sm" },
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_societa" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_societa" + (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_capitale_sociale" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_capitale_sociale" +
                                            (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_fatturato" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_fatturato" + (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_settore" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_settore" + (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_ruolo" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_ruolo" + (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_quotate_borsa" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_quotate_borsa" +
                                            (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c("Domanda", {
                                    ref:
                                      "domande.dati_sindaco.societa" +
                                      (index + 1) +
                                      ".dati_sindaco_data_nomina" +
                                      (index + 1),
                                    refInFor: true,
                                    attrs: {
                                      domanda:
                                        dato[
                                          "dati_sindaco_data_nomina" +
                                            (index + 1)
                                        ],
                                    },
                                    on: {
                                      "update:isValid": _vm.onDomandaValidated,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("br"),
              _vm._m(8),
              _c(
                "q-list",
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Hai mai stipulato polizze per questo rischio?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              " " + _vm._s(_vm.havePolizzaStessoRischio) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.havePolizzaStessoRischio === "SI",
                          expression: " havePolizzaStessoRischio === 'SI' ",
                        },
                      ],
                    },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v("Impresa di assicurazione"),
                              ]),
                              _c("q-item-label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getRispostaQuestionariFromName(
                                      "compagnia"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v("Data di scadenza della copertura"),
                              ]),
                              _c("q-item-label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getRispostaQuestionariFromName(
                                      "scadenza_copertura"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                [
                                  _vm.domandaIsVisible(
                                    _vm.domande
                                      .copertura_assicurativa_precedente
                                      .nome_assicuratore
                                  )
                                    ? _c("Domanda", {
                                        ref: "domande.copertura_assicurativa_precedente.nome_assicuratore",
                                        attrs: {
                                          domanda:
                                            _vm.domande
                                              .copertura_assicurativa_precedente
                                              .nome_assicuratore,
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                [
                                  _vm.domandaIsVisible(
                                    _vm.domande
                                      .copertura_assicurativa_precedente
                                      .massimale
                                  )
                                    ? _c("Domanda", {
                                        ref: "domande.copertura_assicurativa_precedente.massimale",
                                        attrs: {
                                          domanda:
                                            _vm.domande
                                              .copertura_assicurativa_precedente
                                              .massimale,
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                [
                                  _vm.domandaIsVisible(
                                    _vm.domande
                                      .copertura_assicurativa_precedente
                                      .franchigie_scoperti
                                  )
                                    ? _c("Domanda", {
                                        ref: "domande.copertura_assicurativa_precedente.franchigie_scoperti",
                                        attrs: {
                                          domanda:
                                            _vm.domande
                                              .copertura_assicurativa_precedente
                                              .franchigie_scoperti,
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                [
                                  _vm.domandaIsVisible(
                                    _vm.domande
                                      .copertura_assicurativa_precedente
                                      .premio_lordo
                                  )
                                    ? _c("Domanda", {
                                        ref: "domande.copertura_assicurativa_precedente.premio_lordo",
                                        attrs: {
                                          domanda:
                                            _vm.domande
                                              .copertura_assicurativa_precedente
                                              .premio_lordo,
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                [
                                  _vm.domandaIsVisible(
                                    _vm.domande
                                      .copertura_assicurativa_precedente
                                      .retroattivita
                                  )
                                    ? _c("Domanda", {
                                        ref: "domande.copertura_assicurativa_precedente.retroattivita",
                                        attrs: {
                                          domanda:
                                            _vm.domande
                                              .copertura_assicurativa_precedente
                                              .retroattivita,
                                        },
                                        on: {
                                          "update:isValid":
                                            _vm.onDomandaValidated,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(_vm._s(this.PolizzaSchifataDaCompagnia)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      this.PolizzaSchifataDaCompagnia === "SI"
                        ? _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.motivo_rifiuto_compagnia",
                                attrs: {
                                  domanda: _vm.domande.motivo_rifiuto_compagnia,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm._m(9),
              _c(
                "q-list",
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              " " + _vm._s(_vm.haveRichiesteRisarcimento) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.haveRichiesteRisarcimento === "SI",
                          expression: "haveRichiesteRisarcimento === 'SI'",
                        },
                      ],
                    },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.data_richiesta_risarcimento",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri
                                      .data_richiesta_risarcimento,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.periodo_contestazione",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri.periodo_contestazione,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.valore_richiesta_danni",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri.valore_richiesta_danni,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.valore_sinistro_pagato",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri.valore_sinistro_pagato,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.pagamento_con_transazione",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri
                                      .pagamento_con_transazione,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.pagamento_con_sentenza_civile",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri
                                      .pagamento_con_sentenza_civile,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c("Domanda", {
                                ref: "domande.sinistri.descrizione_fatti",
                                attrs: {
                                  domanda:
                                    _vm.domande.sinistri.descrizione_fatti,
                                },
                                on: {
                                  "update:isValid": _vm.onDomandaValidated,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              "Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?"
                            ),
                          ]),
                          _c("q-item-label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.haveConoscenzaPossibiliRichiesteRisarcimento
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.haveConoscenzaPossibiliRichiesteRisarcimento ===
                            "SI",
                          expression:
                            "haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'",
                        },
                      ],
                    },
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("Domanda", {
                            ref: "domande.sinistri.descrizione_possibili_richieste_danni",
                            attrs: {
                              domanda:
                                _vm.domande.sinistri
                                  .descrizione_possibili_richieste_danni,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Informazioni generali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Dati professionali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni sul fatturato")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Distribuzione del fatturato per Attività")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Fatturato internazionale")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Estensione funzioni pubbliche")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [
        _vm._v(
          "Società o Enti nelle quali il proponente è attualmente Sindaco revisore dei conti, Amministratore di società , Attività ODV"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Precedenti assicurazioni per questo rischio")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni sui sinistri")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }